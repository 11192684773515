.subscriptions-list {
    display: flex;
    flex-direction: column;

    @include devices(md) {
        flex-direction: row;
    }
}

.subscription-item {
    flex: 1;
    margin: 0 0 30px;
    padding: 15px;
    padding-top: 30px;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 20%);
    border-radius: 10px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    .title {
        font-size: 280%;
        font-weight: 700;
        color: $black;
        text-align: center;
    }

    .price {
        font-size: 220%;
        text-align: center;
    }

    .benefits-list {
        padding: 5px 0 10px;

        .benefit {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 24px;
            width: 100%;
            text-align: center;

            .benefit-title {
                font-size: 170%;
            }

            .benefit-text {
                font-size: 160%;
                opacity: 0.5;
            }

            .icon {
                color: $black;
                min-width: 24px;
                width: 24px;
                margin-right: 12px;
            }

            &.faded {
                opacity: 0.6;
            }
        }
    }

    .sm-button {
        width: 100%;
    }

    @include devices(md) {
        margin: 0 12px;
    }

    .downgrade-text {
        font-size: 140%;
        text-align: center;
        margin-top: 24px;
        opacity: 0.6;
    }
}