.modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgba(0,0,0,0.4); 

    .modal-block {
        display: inline;
        background-color: #fefefe; 
        padding: 24px;
        border: 1px solid $blue;
        border-radius: 12px;
        text-align: center;
        min-width: 60%;
        line-height: 1.4;

        @include devices(md) {
            min-width: 40%;
            max-width: 50%;
        }

        @include devices(lg) {
            min-width: 30%;
            max-width: 40%;
        }

        .description {
            @include contentMarginBottom;

            h1 {
                font-size: 220%;
                font-weight: 700;
                @include contentMarginBottom;
            }

            h2 {
                font-size: 210%;
                @include contentMarginBottom;
            }

            i {
                font-size: 200%;
                @include contentMarginBottom;
            }

            p {
                font-size: 165%;
            }

            &.authorize-payment {
                p {
                    font-size: 185%;
                    @include contentMarginBottom;

                    strong {
                        font-size: 120%;
                    }
                }
            }

            &.premium-account {
                h2 {
                    width: 50%;
                    margin: 0 auto 16px;
                }
            }
        }

        .button-container {
            display: flex;
            align-items: center;

            &.no-display {
                display: none;
            }
            
            button {
                white-space: nowrap;
                font-size: 170%;
                padding: 12px;
                margin-right: 16px;

                @include last-child {
                    margin-right: 0;
                }
            }
        }

        form {

            .generic-input {
                width: 100%;
                @include contentMarginBottom;

                @include devices(sm) {
                    width: 70%;
                }

                &.bg {
                    background-color: #fff;
                }
            }
        }
    }
}

.authorize-payment-modal {

    .modal-block {

        @include devices(md) {
            width: 700px;
            letter-spacing: 0.5px;
        }
    }
}

.redirect-modal {
    h2 {
        font-size: 24px;
        color: black;
    }

    i {
        margin-top: 20px;
        font-size: 28px;
    }
}

.search-and-list-modal {

    .modal-block {

        @include devices(md) {
            width: 700px;
            letter-spacing: 0.5px;
        }
    }

    .section-title {
        margin-bottom: 24px;
    }
}

.status-card-modal {

    .form-field {
        margin-top: 16px;

        .select-list {
            position: relative;
        
            .selected-box {
                position: relative;
                justify-content: center;
                border: 1px solid #ccc;
                padding: 8px 6px;

                .arrow {
                    position: absolute;
                    top: 50%;
                    right: 12px;
                    transform: translateY(-50%);
                }
            }

            .option-selection {
                margin-top: 0;
                position: absolute;
                width: 100%;
                z-index: 3;
                background-color: rgba(255, 255, 255, 0);

                .option {
                    background: white;
                    p {
                        color: black;
                    }
                    transition: 150ms background ease-in-out;
                    
                    &:first-child {
                        border-top: 0;
                    }

                    &:hover {
                        background: #d3e3f1;
                        // color: white;
                    }

                    &.active {
                        background-color: $blue;
                        color: white;
                    }
                }
            }
        }
    }
}

.add-cardholder-modal {

    .sm-form {
        max-height: 400px;
        overflow: auto;
        margin-bottom: 30px;

        .form-field {

            .select-list {
                position: relative;
                display: inline-block;
    
                &.generic-input {
                    border-bottom: none;
                }
            
                .selected-box {
                    position: relative;
                    justify-content: center;
                    border: 1px solid #ccc;
                    padding: 8px 6px;
    
                    .arrow {
                        position: absolute;
                        top: 50%;
                        right: 12px;
                        transform: translateY(-50%);
                    }
                }
    
                .option-selection {
                    margin-top: 0;
                    position: relative;
                    width: 100%;
                    z-index: 3;
                    background-color: rgba(255, 255, 255, 0);
    
                    .option {
                        background: white;
                        transition: 150ms background ease-in-out;
                        
                        &:first-child {
                            border-top: 0;
                        }
    
                        &:hover {
                            background: #d3e3f1;
                            // color: white;
                        }
    
                        &.active {
                            background-color: $blue;
                            color: white;
                        }
                    }
                }
            }

            &.country-list {
    
                .option-selection {
                    overflow: hidden;
                    height: 200px;
                    border: 1px solid #ccc;
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
    
                    .option-container {
                        position: absolute;
                        overflow: auto;
                        height: 100%;
                    }
                }
            }
    
            input::placeholder, .placeholder {
                text-transform: capitalize;
            }
        }
    }

    .auto-complete-icon {
        left: 60px;
    }

}

.confirm-and-send-modal {

    .sm-typography {
        margin-bottom: 16px;
    }

    h2 {
        font-weight: 700;
        font-size: 200% !important;
    }
}

.enter-cvc-modal {

    .styles_react-code-input-container__tpiKG {
        width: auto !important;
        margin: 0 auto;
        text-align: center;
        margin-top: 16px;
  
        input {
            border: 2px solid $blue !important;
            border-radius: 8px !important;
            margin-right: 6px;
            font-family: $dm-sans !important;
        }
    }

  
    & .form-field {

        & .title {
            display: block;
        }
    }
}

.declaration-modal {
    h2 {
        font-size: 240%;
    }

    p {
        font-size: 160%;
    }
}

.add-cardholder-fee-modal {
    h1 {
        font-size: 240%;
    }
    .description {
        font-size: 160%;
    }

    &.modal-block {
        border: none
    }
}