.help-button {
    position: fixed;
    right: 0;
    bottom: 16px;
    padding: 12px 18px;
    width: auto;

    border-radius: 12px 0 0 12px;

    @include dm-sans-bold;
    font-size: 180%;
    color: $white;
    
    z-index: 999;

    transition: padding 200ms ease-in-out;
    &:hover {
        padding: 12px 32px;
    }

    &.rc-offset {
        bottom: 88px;
    }

    &.dark {
        background-color: $white;
        color: $black;
    }
}