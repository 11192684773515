.container {
    margin: 0 auto;

    @include devices(md) {
        max-width: 520px;
    }
    
    @include devices(lg) {
        max-width: 720px;
    }

    @include devices(xl) {
        max-width: 1000px;
    }

    &.mini-container {
        max-width: 540px;
    }

    &.full-height {
        height: 100%;
    }

    &.flex-center, &.flex-column {
        display: flex;
    }

    &.flex-center {
        align-items: center;
        justify-content: center;
    }

    &.flex-column {
        flex-direction: column;
    }

    &.flex-justify-center {
        justify-content: center;
    }

    &.flex-align-center {
        align-items: center;
    }
}