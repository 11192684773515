//login
.sm-login-container {

    .app-store-badges-wrapper {
        margin-bottom: 32px;

        p {  
            margin-bottom: 64px;

            font-size: 200%;
            text-align: center; 
        }

        &.footer {
            display: none;
            @include devices(tablet) {
                display: block;
            }
        }

        &.mobile {
            display: block;
            @include devices(tablet) {
                display: none;
            }
        }

        .app-store-badges {
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include devices(tablet) {
                flex-direction: row;
                justify-content: center;
            }

            a {
                margin: 0 0 8px 0;
                cursor: pointer;

                img {
                    width: 200px;
                    height: 59px;
                }

                &:last-child {
                    margin-right: 0;
                }

                @include devices(tablet) {
                    margin: 0 24px 0 0;
                }
            }
        }
    }


    .login-container {
        display: flex;
        flex-direction: column;
        align-items: center;    
    
        form {
            width: 100%;
            display: none;

            @include devices(tablet) {
                display: block;
            }
        }

        .field-link-container {
            text-align: center;

            @include devices(tablet) {
                text-align: right;
            }
        }
    }
    
    .activation-container {
        text-align: center;
    }
    
    .password-container {
        text-align: center;
    
      .password-form {
          text-align: left;
      }
    
      .meter-container {
    
          progress {
              width: 100%;
          }
      }
    }
    
    .security-questions-container {
      
      .security-questions-form {
    
          .question-title {
              font-size: 150%;
              font-weight: 600;
              margin-bottom: 16px;
          }
      }
    }
    
    .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    
        .loading {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            i {
                font-size: 250%;
            }

            p {
                margin-top: 24px;
                font-size: 180%;
            }
        }
    }

    .enter-passcode-container {
        text-align: center;
    }
}