.cards-container {

    .content {
        
        &.new-card{
            background-color: $dark-box-background!important;
            border-color: $dark-sub-text!important;

            i {
                color: $dark-sub-text !important;
            }
            .title {
                color: $dark-sub-text !important;
            }
        }

    }

    .carousel-container {
        .carousel-wrapper {
            .carousel-item {
                .card-content {
                    .content {
                        &.frozen-card, &.issued-card, &.status-card {
                            background-color: rgba(0,0,0, 0.8);
                        }
                        .icon, .title {
                            color: $dark-sub-text !important;
                        }
                    }
                }
            }
        }
    }

    .carousel-button-container {

        .button-container {

            .arrow-button {
                background-color: $light-grey;
                color: $black;

                .caret {
                    color: $black;
                }

                &:hover {
                    background-color: $dark-box-background;
                    color: $white;

                    .caret {
                        color: $white;
                    }
                }
            }
        }
    }
}