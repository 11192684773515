.modal-block {
    background-color: $pageBodyDark;

    p {
        color: $white;
    }
}

.status-card-modal {
    .form-field {
        .select-list {
            .option-selection {
                .option {
                    background: black;
                    p {
                        color: white;
                    }
                }
            }
        }
    }
}