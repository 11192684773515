.pin-options-container {

    .pin-header {
        margin-bottom: 48px;

        p {
            font-size: 150%;
            text-align: center;
        }
    }

    .pin-container {
        display: flex;
        justify-content: center;

        .pin-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            color: #fff;
            background-color: $blue;
            margin-right: 16px;
            font: {
                size: 350%;
                weight: 700;
            }
            border-radius: 16px;
            transition: 150ms filter ease-in-out;

            @include last-child {
                margin-right: 0px;
            }
            cursor: pointer;

            &:hover {
                filter: brightness(1.1);
            }
        }
    }

    .pin-error {
        font-size: 150%;
    }

    .back-button {
        margin-top: 48px;
    }
}