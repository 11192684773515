.international-quote-calculator-container {
    max-width: 540px;
    margin: 0 auto;

    .source-container, .recipient-container {
        border: 1px $grey solid;
        border-radius: 10px;
        padding: 16px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .amount-box {
            width: 50%;

            .amount-input-container {
                position: relative;
                width: 70%;
                border: 1px $grey solid;
                padding: 8px;

                .input-header {
                    position: absolute;
                    top: 0;
                    left: 7px;
                    transform: translateY(-70%); 
                    margin: 0;
                    background-color: $pageBody;
                    padding: 0 4px;
                    font-size: 120%;
                    font-weight: 600;
                }

                input, .input-value {
                    display: inline-block;
                    width: 100%;
                    border: none;
                    outline: none;
                    background: transparent;
                    font-size: 140%;

                    &::placeholder {
                        opacity: 0.5;
                    }
                }
                
                .input-value {
                    padding: 2px 0;

                    p {
                        font-size: 120%;
                    }
                }
            }
        }

        .currency-area {
            display: flex;
            align-items: center;
            justify-content: center;

            &.recipient {
                cursor: pointer;
            }

            .currency-flag {
                border-radius: 100%;
                width: 36px;
                height: 36px;
                margin-right: 12px;
                background-position: center center;
            }

            .currency-code {
                font-size: 190%;
                font-weight: 600;
            }

            i {
                margin-left: 12px;
                font-size: 120%;
            }
        }
    }

    .arrival-time {
        margin-top: 24px;
        text-align: center;
        font-size: 175%;
    }

    .information-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        position: relative;
        height: 200px;

        .grid-1 {
            width: 100%;
            height: 100%;
            border-right: 1px $grey solid;
        }

        .information-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            font-size: 160%;

            .information-box {
                display: inline-block;
                border: 1px $grey solid;
                border-radius: 10px;
                padding: 8px 18px;
                background-color: $white;

                &.fee {
                    margin-bottom: 32px;
                }
            }
        }
    }

    .international-calculator-button {
        width: 360px;
        margin-top: 64px;
        margin: 32px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        svg {
            width: 160px;
            margin: 0 auto 24px;
        }
    }

}