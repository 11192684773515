.adverts-container {
    .adverts-grid {
        .adverts-arrow {
            background-color: $white;

            .caret {
                color: $black;
            }
        }
    }
}