.search-and-list {

    .search-input-container {
        position: relative;
        width: 100%;
        border: 1px solid grey;
        border-radius: 24px;
        margin-bottom: 16px;
        padding: 6px;
    
        input {
            width: 100%;
            border: none;
            outline: none;
            background: transparent;
            font-size: 180%;
            padding-left: 28px;
            padding-top: 2px;
        }
    
        i {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-40%);
            font-size: 140%;
        }
    }
    
    .search-and-list-container {
        max-height: 400px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
            background: transparent;  /* Optional: just make scrollbar invisible */
        }
    
        .search-list-container {
            margin-bottom: 32px;
    
            h3 {
                font-size: 160%;
                text-align: left;
                margin-bottom: 16px;
            }
    
            .search-and-list-item-container {
                margin-bottom: 24px;
                cursor: pointer;
    
                .item-area {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
    
                    .item-left {
                        display: flex;
                        align-items: center;
                        
                        .item-avatar {
                            border-radius: 100%;
                            width: 36px;
                            height: 36px;
                            margin-right: 18px;
                            background-position: center center;
                        }
    
                        .item-name {
                            font-size: 190%;
                            font-weight: 600;
                            
                            &-secondary {
                                margin-top: 4px;
                                font-size: 130%;
                            }
                        }
                    }
    
                    .item-right {
    
                        .item-desc {
                            font-size: 190%;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

    .results-errors {
        font-size: 140%;
    }
}