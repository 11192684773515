.payments-container {

    .button-container {
        display: flex;
        margin-bottom: 48px;

        button {
            @include last-child {
                margin-left: 32px;
            }
        }
    }

    .add-cash-steps {
        margin-top: 15px;
        line-height: 1.4;

        p {
            margin: 5px 0;
            font-size: 180%;
            
            &:last-child {
                margin-top: 20px;
            }
        }
    }

    .add-cash-faqs-container {
        margin-top: 50px;
        line-height: 1.4;
        
        .text {
            font-size: 180%;
        }

        strong {
            font-weight: 600;
            font-size: 110%;
        }
    }
}

.payments-menu-container {
    margin-top: 40px;
}

.send-payments-container {
    
    form {
        margin: 0 auto;
        width: 80%;

        .form-field {
            width: 80%;
            margin: 0 auto 30px;
        }
    }

    .button-container {
        display: flex;
        margin-top: 48px;

        button {

            @include last-child {
                margin-left: 32px;
            }
        }
    }
}

.standing-order-container, .direct-debit-container {
    
    .loading-spinner {
        @include dm-sans-medium;
        margin-bottom: 10px;
        font-size: 290%;
        text-align: center;
    }

    .active-container {
        margin-bottom: 32px;
    }

    .cancelled-container {
        margin-bottom: 32px;
    }

    button {
        width: auto;
        padding-left: 64px;
        padding-right: 64px;
    }
}

.view-standing-order-container, .view-direct-debit-container {
    margin-bottom: 32px;

    .view-item {
        border-bottom: 1px solid #707071;
        padding: 16px 0;
        padding-left: 16px;

        h2 {
            font-size: 200%;
            margin-bottom: 0
        }

        h3 {
            font-size: 165%;
            margin-top: 16px;
            margin-bottom: 0;
        }
    }
} 


.payment-status-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .status-icon {

        i {
            font-size: 600%;
            color: $confirmButton;
        }

        &.error {

            i {
                font-size: 600%;
                color: $red; 
            }
        }
    }

    .status-message {
        margin: 24px 0;
        text-align: center;
        font-size: 200%;
        font-weight: 600;
    }
}