.more-account-details-container {
    
    .knowledge-section {
        background-color: $dark-box-background;
    
        li {
            color: $dark-sub-text
        }
    }
}

.more-manage-subscription-container {

    .subscription-item {
        background-color: $dark-box-background;
        
        .benefit-text {
            opacity: 0.7;
        }

        .price, .benefits-list {
            color: #ffffff;
        }
    }
}

.more-cashback-rewards-container {

    .cashback-rewards-container {
        .text {
            color: $white;
        }
    }
}