.review-and-send-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .review-exchange-value-container {
        display: flex;
        align-items: center;
        margin-bottom: 18px;

        .exchange-value {
            text-align: center;
            
            h1 {
                font-size: 600%;
                font-weight: 600;
            }

            p {
                font-size: 200%;
                font-weight: 600;
                opacity: 0.5;
            }
        }

        i {
            font-size: 200%;
            margin: 0 32px;
        }
    }

    .review-recipient-container {
        text-align: center;
        margin-bottom: 32px;

        .section-title {
            font-size: 200%;
            font-weight: 700;
        }

        .recipient-container {
            display: flex;
            align-items: center;;

            .recipient-avatar {
                width: 36px;
                height: 36px;
                background-color: black;
                border-radius: 100%;
                margin-right: 8px
            }

            .recipient-details {

                h3 {
                    text-align: left;
                    font-size: 220%;
                    margin-bottom: 6px;
                }

                p {
                    font-size: 140%;
                }
            }
        }
    }

    .review-arrival-time-container {
        margin-bottom: 32px;
        text-align: center;

        h3 {
            font-size: 220%;
            margin-bottom: 6px;
        }

        p {
            font-size: 270%;
            font-weight: 600;
            text-align: center;
        }
    }

    .review-statement {
        margin-bottom: 32px;

        p {
            font-size: 160%;
            opacity: 0.7;
        }
    }

    .review-add-reference {
        margin-bottom: 24px;

        a {
            font-size: 180%;
            color: $blue;
        }
    }
}