.uk-payee-container {
        
    .uk-payee-item {
        margin-bottom: 28px;
        padding: 16px 64px; 
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 7px;
        background-color: $light-grey;

        &.clickable {
            cursor: pointer;
        }

        .item-left {

            .uk-payee-name {
                font-size: 200%;
                font-family: $dm-sans !important;
                font-weight: 500;
            }

            .uk-payee-account {
                display: flex;
                margin-top: 8px;
                font-size: 170%;

                span {
                    margin: 0px 6px; 
                }
            }
        }

        .item-right {
            
            i {
                font-size: 220%;
            }
        }

        .delete-button {
            background-color: transparent;

            i {
                font-size: 200%;
                color: $black;
            }
        }

    }
}

.international-payee-container {
    text-align: center;
    
    p {
        font-size: 180%;
        margin: 0 auto 32px;
        line-height: 1.4;

        @include devices(xl) {
            width: 960px;
        } 
    }
}

.payee-switch-container {
    display: flex;
    margin: 0 auto 32px;
    width: 50%;

    .switch {
        padding: 16px;
        width: 50%;
        text-align: center;
        border: 2px solid $black;
        cursor: pointer;
        background-color: $white;
        color: $black;

        &.active {
            background-color: $black;
            color: $white;
        }

        h6 {
            font-size: 175%;
            margin-bottom: 0;
        }

        &:last-child {
            border-left-width: 0px;
        }
    }
}

