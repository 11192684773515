h1, h2, h3, h4, h5, h6 {
    color: white;
}

.sm-typography {
    color: $light-text !important;

    &.warning {
        color: $red !important;
    }
}

.sm-button {

    background-color: $white;
    color: $black;

    &.invisible-backdrop {
        background-color: rgba(0,0,0,0);
        color: white;
    }
    
    &.outline {
        border-color: $black;
        color: $black;
        
        &:hover {
            border-color: $white;
            background-color: $dark-box-background;
            color: white;
        }
    }

    &.cancel {
        background-color: #c7c7c7;
    }
}

.sm-login-container {
    background-color: $dark-box-background;
    .statement { 
        p {
            color: white;
        }
    }
}

.sm-box {
    .statement {
        color: white;
    }
}


.menu-item-container {
    background-color: $dark-box-background;

    h3, p {
        color: $white !important;
    }

    & .menu-item-title {
        color: white;
    }

    .menu-item-arrow {
        color: $white;
    }
}

// bullet points
.sm-bullet-point {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0 18px;

    .left {
        .bullet-point {
            border-color: $white;

            &.dash {
                border-color: $white;
            }
        }
    }
}

// currency search
.search-and-list {
    .search-input-container {
        border-color: $white;
        color: $white;

        input { 
            color: $white;
        }
    }

    .search-and-list-item-container {
        color: $white;
    }
}