.wysiwyg {
    line-height: 1.5;

    h1, h2, h3, h4, h5, h6, p, ul, ol, table {
        margin-bottom: 30px;
    }

    p, li, table {
        font-size: 180%;
    }

    :last-child {
        margin-bottom: 0;
    }
}