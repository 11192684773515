.password-strength-meter {
    text-align: left;
}
  
.password-strength-meter-progress {
    width: 100%;
    height: 8px;
    background-color: #eee;

    .progress {
        position: absolute;
        width: 25%;
        height: 100%;
        background-color: $meter-weak;

        &.Fair {
            width: 50%;
            background-color: $meter-fair;
        }

        &.Good {
            width: 75%;
            background-color: $meter-good;
        }

        &.Strong {
            width: 100%;
            background-color: $meter-strong;
        }
    }
}

.password-strength-meter-label {
    font-size: 140%;
    text-align: left;
}