.cards-container {

    .loading-spinner {
        @include dm-sans-medium;
        margin-bottom: 10px;
        font-size: 290%;
        text-align: center;
    }

    .upgrade-account-button {
        position: absolute;
        top: -72px;
        right: -164px;
        font-size: 140%;
        width: auto;
    }

    .carousel-container {

        .carousel-wrapper {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 18px;

            .carousel-item {
                border-radius: 10px;

                .card-content {
                    display: flex;
                    justify-content: flex-end;
                    flex-direction: column;
                    position: relative;
                    
                    img {
                        width: 100%;
                        max-width: 230px;
                    }

                    .content {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        color: $black;
                        border-radius: 11px;
                        
                        @include box-shadow;
                        
                        @include devices(md) {
                            border-radius: 14px;
                        }
                        
                        @include devices(lg) {
                            border-radius: 19px;
                        }

                        &.placeholder-card {
                            background-color: $white;
                            z-index: 2;
                            border-radius: 10px;
                            box-shadow: none;
                        }

                        &.frozen-card, &.issued-card, &.status-card {
                            background-color: rgba(255,255,255,0.7);

                            .expiry-date {
                                opacity: 0.2;
                            }

                            .card-number {
                                opacity: 0.2;
                            }

                            .name {
                                opacity: 0.2;
                            }
                        }

                        &.new-card {
                            background-color: rgba(255,255,255,0.7);
                            border: 3px dashed #bbb;
                            border-radius: 10px;
                            cursor: pointer;
                            box-shadow: none;

                            .icon, .title {
                                color: $grey;
                            }

                            .title {
                                width: 80%;
                                text-align: center;
                            }
                        }

                        &.normal-card {
                            display: block;
                            color: white;
                            height: 100%;
                            transform-origin: center;

                            &.dark-text {
                                color: $black;
                            }
                        }

                        &:hover {
                            cursor: pointer;
                        }

                        .toggle-info {
                            position: absolute;
                            top: 7%;
                            left: 10%;
                            font-size: 180%;
                            border-radius: 6px;
                            padding: 5px;
                            outline: none;
                            border: none;
                        }

                        .expiry-date {
                            position: absolute;
                            bottom: 26%;
                            left: 12%;
                            font-size: 100%;
                            font-weight: 500;

                            @include devices(md) {
                                bottom: 27%;
                                font-size: 120%;
                            }

                            @include devices(lg) {
                                font-size: 140%;
                            }
                        }

                        .card-number {
                            position: absolute;
                            top: 61%;
                            left: 12%;
                            font-size: 90%;
                            font-weight: 500;
                            letter-spacing: 1px;

                            @include devices(md) {
                                font-size: 130%;
                            }

                            @include devices(lg) {
                                font-size: 160%;
                            }
                        }

                        .name {
                            position: absolute;
                            bottom: 18%;
                            left: 12%;
                            font-size: 100%;
                            font-weight: 500;
                            letter-spacing: 1.2px;
                            text-transform: uppercase;

                            @include devices(md) {
                                font-size: 130%;
                            }

                            @include devices(lg) {
                                font-size: 140%;
                            }
                        }

                        & > .toggled {
                            display: flex;
                            align-items: center;
                            background-color: rgba(128,128,128,0.7);
                            border-radius: 8px;
                            padding: 4px 8px;
                            color: white;

                            .copy-to-clip {
                                margin-left: 6px;
                                padding: 4px;
                                font-size: 60%;
                                border-radius: 6px;
                            }
                        }

                        .title {
                            font-size: 130%;
                            margin-bottom: 8px;

                            @include devices(md) {
                                font-size: 160%;
                            }

                            @include devices(lg) {
                                font-size: 200%;
                            }
                        }

                        .icon {
                            font-size: 200%;
                            padding: 8px 6px;

                            @include devices(md) {
                                font-size: 300%;
                                margin-bottom: 8px;
                            }

                            @include devices(lg) {
                                font-size: 400%;
                            }
                        }

                        .sm-button {
                            font-size: 110%;
                            padding: 8px 6px;
                            margin-top: 8px;

                            @include devices(md) {
                                font-size: 120%;
                            }

                            @include devices(lg) {
                                font-size: 160%
                            }
                        }
                    }

                    &.primary {

                        .normal-card {
                            color: $blue
                        }
                    }
                }

                &.left, &.right {
                    transform: scale(0.8);
                    transform-origin : 50% 100%; 
                }
            }
        }
    }

    .carousel-button-container {
        margin-top: 64px;

        .button-container  {
            display: inline-block;

            .buttons {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 24px;
                
                .sm-button {
                    width: 275px;
                    margin-top: 10px;
                    font-size: 250%;
                    padding: 24px;


                    &.unfreeze {
                        background-color: $light-blue;
                        color: $black;
                    }
                }
            }
        }
    }

    @media (max-width: 834px) {
        .carousel-button-container {
            .button-container {
                .buttons {
                    .sm-button {
                        width: 200px;
                        font-size: 175%;
                        padding: 20px;
                    }
                }
            }
        }
    }
}